import React, { useEffect } from "react";
import HomepageLayout from "src/layouts/homepage";
import "./dos.css"; // Import CSS file here

export default function DoNotSellInfo() {
    return (
        <HomepageLayout>
                <iframe src="https://app.termly.io/notify/f74dab8f-2de7-4114-bd7d-25ecf7836712" title="manualslib"></iframe>
        </HomepageLayout>
    );
}
